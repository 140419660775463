import classNames from "classnames"
import React from "react"

import StarIcon from "./Icons/StarIcon"
import Typography from "./Typography"
import { Color } from "../../../constants/V2/color"

import { getBorderColorClass } from "@utils/V2/color"

type Props = {
  color: Color
  classname?: string
}

const Callout = ({ color = Color.Charcoal, classname }: Props) => {
  return (
    <div
      className={classNames(
        getBorderColorClass(color),
        "flex w-fit flex-row items-center justify-center gap-x-4-v2 rounded-40-v2 border border-black-v2 border-opacity-10 px-8-v2 py-[7px] md-v2:px-16-v2",
        classname
      )}
    >
      <StarIcon color={color} />
      <div className="mt-[3px] flex gap-x-4-v2">
        <Typography
          color={color}
          text="Trusted by"
          font="grotesk"
          size="body-md"
          weight="book"
        />
        <Typography
          color={Color.Blue}
          text="200,000"
          font="grotesk"
          size="body-md"
          weight="book"
        />
        <Typography
          color={color}
          text="small businesses"
          font="grotesk"
          size="body-md"
          weight="book"
        />
      </div>
    </div>
  )
}

export default Callout
