//TODO: Add to stories
import classNames from "classnames"
import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import PillButton from "./PillButton"
import { REASON_NOT_AVAILABLE_MAPPING } from "../../../../cart/constants"
import {
  useAddToCart,
  useUpdateProductCartQuantity,
  useAddBundleToCart,
  useUpdateBundleCartQuantity,
} from "../../../../cart/hooks"
import { CartContext } from "../../../../cart/store"
import { Color } from "../../../../constants/V2/color"
import { itemAlreadyInCart } from "../../../../utils/shop"
import CheckmarkIcon from "../Icons/CheckmarkIcon"
import PlusIcon from "../Icons/PlusIcon"
import Typography from "../Typography"

import { isPurchasableProduct, PurchasableItem } from "@hooks/V2/storyblok"
import { trackSegmentEvent } from "@utils/analytics"
import { formatPrice } from "@utils/price"
import { getAccentColor, getFillColorClass } from "@utils/V2/color"

export type Product = {
  id: string
  sku: string
}

type Props = {
  style?: "solid" | "bordered"
  size?: "small" | "medium" | "large"
  hideIcon?: boolean
  className?: string
  purchasableItem: PurchasableItem
  quantity?: number
  changeCartQuantity?: boolean
  reasonNotAvailable?: string
  buttonColor?: Color
  price?: number
  overrideTextColor?: Color
  openCartPreview?: boolean
  fontSize?: ComponentProps<typeof Typography>["size"]
  iconSize?: number
  waitlistLink?: string
  onClick?: () => void
}

const AddToCartButton = ({
  style = "bordered",
  size = "large",
  hideIcon,
  className,
  purchasableItem,
  quantity = 1,
  changeCartQuantity,
  reasonNotAvailable,
  buttonColor = Color.Blue,
  price,
  overrideTextColor = getAccentColor(buttonColor),
  openCartPreview = true,
  iconSize = 18,
  waitlistLink,
  onClick,
}: Props) => {
  const cartContext = useContext(CartContext)

  const alreadyAdded = itemAlreadyInCart(purchasableItem)

  const iconStyles =
    "group-hover:fill-white-v2 group-hover:rotate-90 group-focus-visible:rotate-90 transform transition duration-300 ease-in-out"

  const addProductToCart = useAddToCart()
  const addBundleToCart = useAddBundleToCart()
  const [updateCart, setUpdateCart] = useState(changeCartQuantity)
  const [disabled, setDisabled] = useState(false)
  const [handlingAddToCart, setHandlingAddToCart] = useState(false)

  const handleAddToCart = useCallback(() => {
    setHandlingAddToCart(true)
    if (isPurchasableProduct(purchasableItem)) {
      addProductToCart(purchasableItem.id, purchasableItem.sku, quantity).then(
        () => setHandlingAddToCart(false)
      )
      trackSegmentEvent("shop_add_to_cart", {
        sku: purchasableItem.sku,
        quantity: quantity,
      })
    } else {
      addBundleToCart(
        purchasableItem.id,
        purchasableItem.bundleCode,
        quantity
      ).then(() => setHandlingAddToCart(false))
      trackSegmentEvent("shop_add_to_cart", {
        sku: purchasableItem.bundleCode,
        quantity: quantity,
      })
    }
    if (openCartPreview) {
      cartContext.setCartPreviewOpen(true)
    }
    if (onClick) {
      onClick()
    }
    setUpdateCart(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quantity,
    addProductToCart,
    addBundleToCart,
    purchasableItem,
    cartContext,
  ])

  const updateProductCartQuantity = useUpdateProductCartQuantity()
  const updateBundleCartQuantity = useUpdateBundleCartQuantity()
  const handleUpdateCart = useCallback(
    (purchasableItem: PurchasableItem) => {
      isPurchasableProduct(purchasableItem)
        ? updateProductCartQuantity(purchasableItem.sku, quantity)
        : updateBundleCartQuantity(purchasableItem.bundleCode, quantity)
      setUpdateCart(false)
      if (changeCartQuantity === true) {
        setUpdateCart(true)
      }

      if (onClick) {
        onClick()
      }
    },
    [changeCartQuantity, quantity, updateProductCartQuantity]
  )

  useEffect(() => {
    if (changeCartQuantity === true) {
      setUpdateCart(true)
    } else {
      setUpdateCart(false)
    }
  }, [changeCartQuantity])

  useEffect(() => {
    setDisabled(cartContext.cartDisabled)
  }, [cartContext.cartDisabled])

  if (reasonNotAvailable) {
    //TODO - in future use waitlist SDK
    if (waitlistLink && reasonNotAvailable === "pre_order_only") {
      return (
        <PillButton
          size={size}
          style="solid"
          className={className}
          color={Color.Blue}
          text="Join the waitlist"
          linkUrl={waitlistLink}
        />
      )
    }
    return (
      <PillButton
        size={size}
        disabled={true}
        hideIcon={true}
        style="solid"
        className={className}
        color={Color.Blue}
        text={REASON_NOT_AVAILABLE_MAPPING[reasonNotAvailable]}
      />
    )
  }

  if (!alreadyAdded) {
    return (
      <PillButton
        text="Add to cart"
        secondaryText={
          price
            ? formatPrice(price * quantity, {
                hasThousandsSeparator: true,
                shouldShowDecimals: true,
              })
            : ""
        }
        iconPosition="right"
        size={size}
        style={style}
        icon={
          <PlusIcon
            className={classNames(
              getFillColorClass(overrideTextColor),
              iconStyles
            )}
            height={iconSize}
            width={iconSize}
          />
        }
        hideIcon={hideIcon}
        color={buttonColor}
        onClick={handleAddToCart}
        disabled={disabled || handlingAddToCart}
        className={className}
        data-test-id="add-to-cart"
        overrideTextColor={overrideTextColor}
      />
    )
  }

  if (alreadyAdded && updateCart === false) {
    return (
      <PillButton
        text="In cart"
        size={size}
        style="bordered"
        icon={
          <CheckmarkIcon className="stroke-blue-v2 group-hover:stroke-white-v2" />
        }
        hideIcon={hideIcon}
        color={Color.Blue}
        className={className}
        disabled={disabled}
        overrideTextColor={Color.Blue}
      />
    )
  }

  if (alreadyAdded && updateCart === true) {
    return (
      <PillButton
        text="Update cart"
        size={size}
        style="solid"
        icon={
          <PlusIcon
            className={classNames(getFillColorClass(Color.White), iconStyles)}
            height={iconSize}
            width={iconSize}
          />
        }
        hideIcon={hideIcon}
        color={Color.Blue}
        onClick={() => {
          handleUpdateCart(purchasableItem)
        }}
        disabled={disabled}
        className={className}
      />
    )
  }
}

export default AddToCartButton
